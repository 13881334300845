import React from 'react';

const Crud = () => {
    return (
        <div>
            CRUD table here
        </div>
    );
}


export default Crud;
