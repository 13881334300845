import React, { useState, useEffect } from "react";
import { Col, Row, Button } from 'reactstrap';
import "../assets/css/contact.css";
import LoadingSpinner from "./LoadingSpinner";
import MessageSent from "./MessageSent";

export default function ContactForm() {
    const [loading, setLoading] = useState(false);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [subject, setSubject] = useState("");
    // const [emailSubject, setEmailSubject] = useState("Contact from Rooms4RentHarlow web form");
    const [message, setMessage] = useState("")
    const [nameIsValid, setNameIsValid] = useState("")
    const [emailIsValid, setEmailIsValid] = useState("")
    const [emailBody, setEmailBody] = useState("");
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [messageSent, setMessageSent] = useState("");
    const [messageSuccess, setMessageSuccess] = useState(false);
    const emailSubject = "Contact from Rooms4RentHarlow web form";




    const handleChange = (event) => {
        // var sanitizedValue = sanitizeInput(event.target.name, event.target.value)
        var sanitizedValue = ""

        switch (event.target.name) {
            case "email":
                sanitizedValue = event.target.value.replace(/[^\w.@_+\-=]/gi, '');
                break;
            default:
                sanitizedValue = event.target.value.replace(/[^\w\s?,.]/gi, '');;
        }


        // console.log(sanitizedValue);
        switch (event.target.name) {
            case "name":
                setName(sanitizedValue);
                document.getElementById("name").style.border = '1px solid #cecece';
                if (event.target.value.trim().length < 1) {
                    setNameIsValid(false);
                }
                else {
                    setNameIsValid(true);
                }
                break;
            case "email":
                setEmail(sanitizedValue);
                document.getElementById("email").style.border = '1px solid #cecece';
                if (sanitizedValue.trim().length < 1) {
                    setEmailIsValid(false)
                }
                else {
                    var reg = /^[A-Z0-9._%+-]+@([A-Z0-9-]+\.)+[A-Z]{2,4}$/i;
                    if (!reg.test(sanitizedValue)) {
                        setEmailIsValid(false)
                    }
                    else {
                        setEmailIsValid(true)
                    }
                }
                break;
            case "phone":
                setPhone(sanitizedValue);
                break;
            case "subject":
                setSubject(sanitizedValue);
                break;
            case "message":
                setMessage(sanitizedValue);
                break;
            default:
            // console.log(sanitizedValue);
        }

    }

    useEffect(() => {
        setEmailBody("Name: " + name + "<br>Email: " + email + "<br>Phone: " + phone + "<br>Subject: " + subject + "<br><br>Message:<br>" + message)
        // setEmailBody("Test")

    }, [name, email, phone, subject, message]);


    const handleSend = () => {
        // console.log("submitted clicked")

        if ((nameIsValid && emailIsValid)) {
            setLoading(true);
            // setFormSubmitted(true);
            // setMessageSuccess(true);
            // setMessageSent("Message sent. We will be in touch soon!");

            fetch('https://www.rooms4rentharlow.co.uk/scripts/send-email.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: `email_from=laura@rooms4rentharlow.co.uk&email_to=laura@rooms4rentharlow.co.uk&email_subject=${emailSubject}&email_message=${emailBody}`,

            })
                .then(response => {
                    if (response.ok) {
                        // console.log('Email sent successfully!');
                        setLoading(false);
                        setName("");
                        setEmail("");
                        setPhone("");
                        setSubject("");
                        setMessage("");
                        setMessageSuccess(true);
                        setNameIsValid(false);
                        setEmailIsValid(false);
                        setFormSubmitted(true);
                        setMessageSent("Message sent. We will be in touch soon!");
                    } else {
                        // console.log('Failed to send email.');
                        setLoading(false);
                        setMessageSuccess(false);
                        setMessageSent("Message failed to send. Please try again!")
                    }
                })
                .catch(error => {
                    console.error('Error:', error);
                });

            console.log(email, emailSubject, emailBody);



        }
        else {
            if (!nameIsValid) {
                document.getElementById("name").style.border = '2px solid #ff0000';
            }
            if (!emailIsValid) {
                document.getElementById("email").style.border = '2px solid #ff0000';
                // //console.log(pEmail.trim().length, pEmail)
            }
        }
    }

    // useEffect(() => {
    //     let timeoutId = setTimeout(() => {
    //         setLoading(false);
    //         // setMessageSent(false);

    //     }, 1000);

    //     return () => {
    //         clearTimeout(timeoutId);
    //     };

    // }, [messageSent]);

    return (
        <Row>
            {loading === true ? <LoadingSpinner /> :
                <>
                    {formSubmitted === true ? "" :
                        <>

                            <Col lg="6" xs="12">
                                <input type="text" placeholder="Name *" className="name-input" id="name" name="name" value={name} onChange={handleChange} maxLength={75}></input>
                            </Col>
                            <Col lg="6" xs="12">
                                <input type="email" placeholder="Email *" className="email-input" id="email" name="email" value={email} onChange={handleChange} maxLength={75}></input>
                            </Col>
                            <Col xs="12">
                                <input type="text" placeholder="Phone " className="phone-input" id="phone" name="phone" value={phone} onChange={handleChange} maxLength={75}></input>
                            </Col>
                            <Col xs="12">
                                <input type="text" placeholder="Subject" className="subject-input" id="subject" name="subject" value={subject} onChange={handleChange} maxLength={75} ></input>
                            </Col>
                            <Col xs="12">
                                <textarea
                                    placeholder="Message"
                                    className="message-input"
                                    rows={6}
                                    value={message}
                                    maxLength={500}
                                    id="message" name="message" onChange={handleChange}
                                />
                            </Col>
                            <Col xs="12" className="mt-2">
                                <Button className="send-button" onClick={handleSend}> <div className="list-button-text">Send</div></Button>
                                {/* <button className="send-button" onClick={handleSend}>Send</button> */}
                            </Col>
                        </>
                    }

                </>
            }
            {messageSuccess === true ? <MessageSent messageSent={messageSent} /> : <div className="text-center mt-2 failed-sent-message">{messageSent}</div>}
        </Row>
    );
}