//import React, { useState, useEffect } from "react";
import React from "react";
import { Col, Row } from 'reactstrap';
import pd_logo from "../assets/img/pd_logo.png";
import "../assets/css/header.css";
import fb_logo from "../assets/img/fb_logo.png";
// import { useMsal, useIsAuthenticated } from "@azure/msal-react";
// import { InteractionStatus } from '@azure/msal-browser';


export default function Header({ siteContentH }) {

    // const { instance, inProgress } = useMsal();
    // const isAuthenticated = useIsAuthenticated();


    // function handleLogin() {
    //     if (!isAuthenticated && inProgress === InteractionStatus.None) {

    //         instance.handleRedirectPromise()
    //             .then((tokenResponse) => {
    //                 if (!tokenResponse) {
    //                     const accounts = instance.getAllAccounts();
    //                     if (accounts.length === 0) {
    //                         instance.loginRedirect();
    //                     }
    //                 } else {
    //                     alert("error during handleRedirectPromise")
    //                 }
    //             })
    //             .catch(err => {
    //                 console.error(err);
    //                 alert(err);
    //             });
    //     };
    // };

    // function handleLogout() {
    //     instance.logoutRedirect().catch(e => {
    //         console.error(e);
    //     });
    // }

    return (
        <div className="container-mb">
            <Row>
                {/* <Col xs="12" className="text-end login-text mb-0 pb-0">
                    {!isAuthenticated ? <div onClick={handleLogin}>Login</div> :
                        <div onClick={handleLogout}>Logout</div>

{`tel:${siteContent.find(item => item.PVALUE1 === "CIPHONE")?.PVALUE2}`}


                    }
                </Col> */}
                <Col md="7" xs="9" className="contact mt-1">Contact Laura:&nbsp;

                    {/* <a className="formatted-link" href="tel:+44 7976 494 413" >07976 494 413</a>
                 */}
                    <a className="formatted-link"
                        href={`tel:${siteContentH.find(item => item.PVALUE1 === "CIPHONE")?.PVALUE2}`}>
                        {siteContentH.find(item => item.PVALUE1 === "CPHONE")?.PVALUE2}
                    </a>



                </Col>
                <Col md="5" xs="3" className="text-end mt-3"><a href="https://www.facebook.com/harlowrooms4rent" className="links formatted-link"><span className="links formatted-link contact-2">Available Properties &nbsp;</span><img src={fb_logo} alt="Facebook Logo" /></a></Col>
                <Col xxl="2" xs="3" className="mt-3 header-logo">
                    <img src={pd_logo} alt="PD Properties" className="logo" />
                </Col>
                <Col xxl="10" xs="9">
                    <Row className="mt-3">
                        <Col xs="12" className="title" >
                            WELCOME TO ROOMS 4 RENT HARLOW
                        </Col>
                        <Col xs="12" className="sub-title">
                            Well established professional and friendly service
                        </Col>
                        {/* <div className="ls-block">
                            <Col xl="7" xs="12" className="contact mt-1">Contact Laura: <a className="formatted-link" href="tel:+44 7976 494 413" >07976 494 413</a></Col>
                            <Col xl="5" xs="12" className="text-end mt-3"><a href="https://www.facebook.com/harlowrooms4rent" className="links formatted-link contact-2">Available Properties &nbsp; <img src={fb_logo} alt="Facebook Logo" /></a></Col>
                        </div> */}
                    </Row>
                </Col>
            </Row>
        </div>

    );
}