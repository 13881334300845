import { Spinner, Col, Row } from "reactstrap"


const LoadingSpinner = () => {
    return (
        <>
            <div className="loading-spinner d-flex align-items-center justify-content-center">
                <Row>
                    <Col xs="12" className="text-center">
                        <div className="mt-10">
                            <Spinner style={{ width: '3rem', height: '3rem' }} />
                        </div>
                    </Col>
                </Row >
            </div>
        </>
    )
}

export default LoadingSpinner