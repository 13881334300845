import React, { useState, useEffect } from "react";
import { Col, Row, Button } from 'reactstrap';
import "../assets/css/body.css"
// import { HiOutlineChat } from "react-icons/hi";
// import fb_logo from "../assets/img/fb_logo.png";
// import map_pin from "../assets/img/map_pin.jpg"
import ContactForm from "./ContactForm";
// import grassimage from "../assets/img/grassimage.jpg"
import { AiOutlineCopyright } from "react-icons/ai";
import video from "../assets/video/file.mp4"

export default function Body({ siteContent }) {
    const env = process.env.REACT_APP_ENV;
    const [counter, setCounter] = useState(0);
    const data1 = siteContent.filter(item => item.PVALUE1 === 'LETTING');
    const data2 = siteContent.filter(item => item.PVALUE1 === 'ROOMS');
    const data3 = siteContent.filter(item => item.PVALUE1 === 'BEDFLAT');
    useEffect(() => {
        if (env !== "DEV") {
            fetch('https://www.rooms4rentharlow.co.uk/scripts/counter.php').then(response => response.text()).then(data => {
                setCounter(parseInt(data));
            })
                .catch(error => { console.error('Error fetching counter value:', error); });
        }
    }, [env]);

    return (
        <div className="body">
            <div id="video-container">
                <video id="background-video" autoPlay loop muted>
                    <source src={video} type="video/mp4" />
                </video>
                <div className="text-overlay">
                    <div className="container-mb">
                        <Row className="rates-row">
                            <Col className="rates">
                                <div>
                                    <p>{siteContent.find(item => item.PKEY === "KEY010")?.PVALUE2}</p>
                                    <p>{siteContent.find(item => item.PKEY === "KEY020")?.PVALUE2}</p>
                                    <p>{siteContent.find(item => item.PKEY === "KEY030")?.PVALUE2}</p>
                                    <p>{siteContent.find(item => item.PKEY === "KEY040")?.PVALUE2}</p>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>

            <div className="body-row">
                <div className="container-mb">
                    <div className="body-title">
                        {siteContent.find(item => item.PKEY === "KEY050")?.PVALUE2}
                    </div>
                    <div className="options">{siteContent.find(item => item.PVALUE1 === "LETTINGTITLE")?.PVALUE2}</div>
                    <ul className="list">
                        {

                            data1.map((item) => (
                                <li key={item.ID}>{item.PVALUE2}</li>
                            ))
                        }
                    </ul>
                    <div className="options">{siteContent.find(item => item.PVALUE1 === "ROOMSTITLE")?.PVALUE2}</div>
                    <ul className="list">
                        {
                            data2.map((item) => (
                                <li key={item.ID}>{item.PVALUE2}</li>
                            ))
                        }
                    </ul>
                    <div className="options">{siteContent.find(item => item.PVALUE1 === "BEDFLATTITLE")?.PVALUE2}</div>
                    <ul className="list">
                        {
                            data3.map((item) => (
                                <li key={item.ID}>{item.PVALUE2}</li>
                            ))
                        }
                    </ul>
                    <br />
                    <div className="text-center">
                        <a target="_blank" rel="noreferrer" href={siteContent.find(item => item.PVALUE1 === "ALINK")?.PVALUE2}><Button className="list-button rounded-pill" >
                            <div className="list-button-text">{siteContent.find(item => item.PVALUE1 === "ABUTTON")?.PVALUE2}</div></Button></a>
                    </div>
                </div>
            </div>
            <div className="container-mb">
                <Row className="mb-4">
                    <Col md="12" xs="12" >
                        <h2 className="mt-5 contact-title">Get In Contact</h2>
                        <h5 className="mt-3 ms-2 contact-details">Phone Laura: <a className="formatted-link"
                            href={`tel:${siteContent.find(item => item.PVALUE1 === "CIPHONE")?.PVALUE2}`}>
                            {siteContent.find(item => item.PVALUE1 === "CPHONE")?.PVALUE2}
                        </a></h5>
                        <h5 className="mt-2 ms-2 contact-details">Email: <a className="formatted-link"
                            href={`mailto:${siteContent.find(item => item.PVALUE1 === "CEMAIL")?.PVALUE2}`}>
                            {siteContent.find(item => item.PVALUE1 === "CEMAIL")?.PVALUE2}
                        </a></h5>
                        <ContactForm />
                    </Col>
                </Row>
            </div>
            <div className="container-mb mb-4">
                <Row className="mt-4">
                    <iframe title="mapview" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d79000.59913532967!2d0.03993853605673949!3d51.773835102715694!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47d89098ea9c95c5%3A0xbeebc5ba8ebf1690!2sHarlow!5e0!3m2!1sen!2suk!4v1684909816929!5m2!1sen!2suk" style={{ height: "450px", width: "100%", frameBorder: "0" }}></iframe>
                </Row>
            </div>

            <footer className="footer-text">
                <Row>
                    <Col xs="9" className="text-start"> <span ><AiOutlineCopyright className="mb-1 me-1" />{new Date().getFullYear()} P DOLAN PROPERTY</span></Col>
                    <Col xs="3" className="text-end"><span >{counter}</span></Col>
                    <Col xs="9" className="text-start"><span >V20240825_1747</span></Col>
                    <Col xs="3" className="text-end"> <span ><a className="formatted-link-footer" target="_blank" rel="noreferrer" href="https://www.mobyte.com/">Powered by Mobyte</a></span></Col>
                </Row>
            </footer>
        </div >



    );
}