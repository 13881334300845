import { useEffect, useState } from "react";
import Header from "../src/Components/Header"
import Body from "../src/Components/Body"
import "../src/assets/css/body.css"
import 'bootstrap/dist/css/bootstrap.min.css';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import Crud from "./Components/Crud";


function App() {
  const [siteContent, setSiteContent] = useState([]);

  useEffect(() => {
    fetch('https://www.rooms4rentharlow.co.uk/scripts/Rooms4db.php',)
      .then((response) => response.json())
      .then((jsonData) => {
        setSiteContent(jsonData);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, []);

  return (
    <div className="App">
      <UnauthenticatedTemplate>
        <Header siteContentH={siteContent} />
        <Body siteContent={siteContent} />
      </UnauthenticatedTemplate>

      <AuthenticatedTemplate>
        <Header />
        <Crud />

      </AuthenticatedTemplate>

    </div>
  );
}

export default App;
