import { Col, Row } from "reactstrap"
import sendCheck from "../assets/icons/send-check.svg"

const MessageSent = ({ messageSent }) => {
    return (
        <>
            <div className="message-sent d-flex align-items-center justify-content-center">

                <Row >
                    <Col xs="12" className="text-center">
                        <div className="mt-10 ">
                            <img src={sendCheck} className="sent-icon" alt="Sent Message Icon" />
                        </div>
                    </Col>
                    <Col xs="12" style={{ marginTop: "15px" }}>
                        <h5 className="text-center mt-2 sent-message">{messageSent}</h5>
                    </Col>
                </Row ></div>
        </>
    )
}

export default MessageSent